import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, IconButton, Collapse, Paper, Typography, Grid, CircularProgress } from '@mui/material';
import BallotIcon from '@mui/icons-material/Ballot';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

import S111Legend from './legend-menu-items/ofs-surface-currents-legend.js';
import WWALegend from './legend-menu-items/wwa-legend.js';
import NDFDLegend from './legend-menu-items/ndfd-legend.js';
import TropicalCycloneLegend from './legend-menu-items/tropical-cyclone-legend.js';
import MRMSLegend from './legend-menu-items/mrms-legend.js';
import MRMSQPELegend from './legend-menu-items/mrms-qpe-legend.js';
import NBSLegend from './legend-menu-items/nbs-legend.js';
import STOFSLegend from './legend-menu-items/stofs-legend.js';
//import SatelliteLegend from './legend-menu-items/satellite-legend.js';
import SSTLegend from './legend-menu-items/sst-legend.js';
import S100Legend from './legend-menu-items/s100-legend.js';
import FederalBoundariesLegend from './legend-menu-items/federal-boundaries-legend.js';
import ZoneForecastsLegend from './legend-menu-items/zone-forecasts-legend.js';
import PathogenLegend from './legend-menu-items/pathogen-legend.js';
import LightningDensityLegend from './legend-menu-items/ltng-den-legend.js';
import ConvectiveLegend from './legend-menu-items/convective-legend.js';
import SurfaceObsLegend from './legend-menu-items/surface-obs-legend.js';
import VLMLegend from './legend-menu-items/vlm-legend.js';

const sxStyles = {
    legendItemsContainer: {
        marginTop: '2.5em',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: {
            xs: 'calc(100vh - 25.5em)',
            sm: 'calc(100vh - 24.5em)',
            md: 'calc(100vh - 22.5em)',
            lg: 'calc(100vh - 17em)',
        },
    },
    legendContainer : {
        position: 'absolute',
        top: '3.5rem',
        right: '0rem',
        margin: 0,
        padding: '0.75em',
        width: {
            lg: '19.5em',
            sm: '19.5em',
            xs: '19.5em'
        },
        backgroundColor: 'rgba(255,255,255,1)',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '0.15em',
    },
    legendMenuOpenButton : {
        backgroundColor: '#FFFFFF',
        color: 'primary.main',
        '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
        },
        borderRadius: 25,
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.5)',
    },
};

// Define JSS styles for non-mui components
const classes = {
    legendItemDivider: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10
    },
};

const IconLabelTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        fontSize: '65%',
        '& *' : {
            color: theme.palette.primary.light,
        }
    },
}));

/**
* Wrapper for conditionally rendering a tooltip only at cellphone size
*/
function CellPhoneToolTip(props) {
    const theme = useTheme();
    if (useMediaQuery(theme.breakpoints.only('xs'))) {
        return(
            <IconLabelTooltip arrow title="LEGEND" placement="top">
                {props.children}
            </IconLabelTooltip>
        );
    }
    return(
        <>
            {props.children}
        </>
    );
}

/**
* Button for toggling legend menu
*
* @prop (bool) legendMenuOn - True if legend menu should be displayed
* @prop (func) toggleLegendMenu - Callback for setting legend menu on/off (turns off layer menu if legend menu being turned on)
*/
function LegendMenuOpenButton(props) {
    const theme = useTheme();
    const buttonContent = <><BallotIcon/><Typography variant="caption" style={{fontSize: useMediaQuery(theme.breakpoints.down('xs')) ? "75%" : "" }}>{useMediaQuery(theme.breakpoints.only('xs')) ? '' : 'Legend'}</Typography></>;
    const cellphone = (useMediaQuery(theme.breakpoints.only('xs')));

    return (
        <CellPhoneToolTip>
            { (cellphone) ?
                <IconButton size="small"
                    onClick={props.toggleLegendMenu}
                    sx={{
                        ...sxStyles.legendMenuOpenButton,
                        backgroundColor: props.legendMenuOn ? 'primary.main' : '#fff',
                        color: props.legendMenuOn ? 'primary.contrastText' : 'primary.main'
                    }}
                >
                    {buttonContent}
                </IconButton>
                :
                <Button size="small"
                    onClick={props.toggleLegendMenu}
                    sx={{
                        ...sxStyles.legendMenuOpenButton,
                        backgroundColor: props.legendMenuOn ? 'primary.main' : '#fff',
                        color: props.legendMenuOn ? 'primary.contrastText' : 'primary.main'
                    }}
                >
                    {buttonContent}
                </Button>
            }
        </CellPhoneToolTip>
    );
}

/**
* Content for LegendMenu component
*
* @prop (obj) styleInfo - top-level state obj containing current legend capabilities
* @prop (obj) layerToggles - top-level state obj mapping each layer to its on/off state
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources" (helps handle dynamicLayerList behavior + currentSource info)
* @prop (func) toggleLegendMenu - Callback for setting legend menu on/off (turns off layer menu if legend menu being turned on)
*
* Reference on styleInfo object:
*
* This object lives at the top level in app.js and is populated by parses written in capabilities-handler-wms.js
* It has the following format
*   { <product/layer name> :
*       { <OL layer name> :
*           [
*               { name: <name of style parsed from capabilities>,
*                 title: <value parsed from capabilities - used as label in viewer>,
*                 width: <width (int) parsed from capabilities>,
*                 height: <height (int) parsed from capabilities>,
*                 format: <format (ex img/png) parsed from capabilities>,
*                 url: <url of legend image parsed from capabilities>,
*               }, ...
*           ]
*       }, ...
*   }
*
*   Note on keys above:
*   - product/layer name corresponds to top level keys in config.js as well as the keys in the layerToggles state object (ie. mrms, ndfd, wwa, etc.)
*   - OL layer name references the actual name of the layer in capabilities from which the style info originate, this name also corresponds
*   to values passed for the layer parameter of Open Layers sources as well as the names of Open Layers sources defined in config.js
*/
function LegendMenuContent(props) {

    return (
        <>
        <IconButton size="large" color="primary" onClick={props.toggleLegendMenu} sx={sxStyles.closeButton} >
            <CloseIcon />
        </IconButton>
        <Grid container sx={sxStyles.legendItemsContainer} >
            { props.layerToggles.ndfd ?
                <Grid item xs={12} >
                    { props.styleInfo.ndfd ?
                        <NDFDLegend
                            activeVariable={props.olLayerState['ndfd'].currentSource} // This hardcoded key originates from config.js
                            ndfdStyleInfo={props.styleInfo.ndfd}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false }
            { props.layerToggles.mrms ?
                <Grid item xs={12} >
                    { props.styleInfo.mrms ?
                        <MRMSLegend
                            MRMSStyleInfo={props.styleInfo.mrms}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false }
            { props.layerToggles.mrms_qpe ?
                <Grid item xs={12} >
                    { props.styleInfo.mrms_qpe ?
                        <MRMSQPELegend
                            MRMSQPEStyleInfo={props.styleInfo.mrms_qpe}
                            olLayerState={props.olLayerState}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider}></div>
                </Grid>
            : false }
            { props.layerToggles.nbs ?
                <Grid item xs={12} >
                    { props.styleInfo.nbs ?
                        <NBSLegend // Hardcoded to retrieve active source from second value in olLayerState stylesParam for bathymetry
                            activeStyle={(props.olLayerState.bathymetry.stylesParam) ? props.olLayerState.bathymetry.stylesParam[0] : "nbs_elevation"}
                            nbsStyleInfo={props.styleInfo.nbs}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false }
            { props.layerToggles.s111 ?
                <Grid item xs={12} >
                    { props.styleInfo.s111 ?
                        <S111Legend
                            S111StyleInfo={props.styleInfo.s111}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false }
            { props.layerToggles.convective_outlooks ?
                <Grid item xs={12} >
                        <ConvectiveLegend
                            olLayerState={props.olLayerState}
                        />
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false }
            { props.layerToggles.surface_obs ?
                <Grid item xs={12} >
                        <SurfaceObsLegend
                            olLayerState={props.olLayerState}
                        />
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false }
            { props.layerToggles.vlm ?
                <Grid item xs={12} >
                    { props.styleInfo.vlm ?
                        <VLMLegend
                            VLMStyleInfo={props.styleInfo.vlm}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.wwa ?
                <Grid item xs={12} >
                    { props.styleInfo.wwa ?
                        <WWALegend
                            WWAStyleInfo={props.styleInfo.wwa}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.zone_forecasts ?
                <Grid item xs={12} >
                    <ZoneForecastsLegend
                        olLayerState={props.olLayerState}
                    />
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.ltng_den ?
                <Grid item xs={12} >
                    <LightningDensityLegend
                        LightningDensityStyleInfo={props.styleInfo.ltng_den}
                    />
                    <div style={classes.legendItemDivider}></div>
                </Grid>
            :false}
            { props.layerToggles.pathogen ?
                <Grid item xs={12} >
                    <PathogenLegend
                        PathogenStyleInfo={props.styleInfo.pathogen}
                    />
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.tropical_cyclones ?
                <Grid item xs={12} >
                    { props.styleInfo.tropical_cyclones ?
                        <TropicalCycloneLegend
                            tropicalCycloneStyleInfo={props.styleInfo.tropical_cyclones}
                            cycloneLayerList={(props.olLayerState && props.olLayerState.tropical_cyclones) ? props.olLayerState.tropical_cyclones.layersParam : null}
                            activeTropicalSSLayer={(props.olLayerState && props.olLayerState.tropical_ss) ? props.olLayerState.tropical_ss.layersParam : null}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.stofs ?
                <Grid item xs={12} >
                    { props.styleInfo.stofs ?
                        <STOFSLegend
                            STOFSStyleInfo={props.styleInfo.stofs}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.sst ?
                <Grid item xs={12} >
                    { props.styleInfo.sst ?
                        <SSTLegend
                           SSTStyleInfo={props.styleInfo.sst}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.s100 ?
                <Grid item xs={12} >
                    { props.styleInfo.s100 ?
                        <S100Legend
                           S100StyleInfo={props.styleInfo.s100}
                        />
                    : <CircularProgress /> }
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
            { props.layerToggles.federal_agency_boundaries ?
                <Grid item xs={12} >
                    <FederalBoundariesLegend
                        olLayerState={props.olLayerState}
                    />
                    <div style={classes.legendItemDivider} ></div>
                </Grid>
            : false}
      </Grid>
      </>
    );
}

//

/**
*  Top-level Legend component contains legend menu container and button to toggle it
*
* @prop (bool) legendMenuOn - True if legend menu should be displayed
* @prop (func) setLegendMenuOn - Callback for setting legend menu on/off
* @prop (func) setLayerMenuOn - Callback for setting layer menu on/off (legend and layer menus are mutually exclusive)
* @prop (obj) styleInfo - top-level state obj containing current legend capabilities
* @prop (obj) layerToggles - top-level state obj mapping each layer to its on/off state
* @prop (func) updateMapClickPopup - callback for updating mapClickPopupOn
* @prop (bool) mapClickPopupOn - true if the feature info popup is open, else false
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*/
function LegendMenu(props) {

    const toggleLegendMenu = () => {
        const curState = props.legendMenuOn;
        if (!curState) {
            props.setLayerMenuOn(false);
        }
        // Turn off feature info popup if menu is going from false to true and feature info popup is on
        if (props.mapClickPopupOn && !curState) {
            props.updateMapClickPopup(false);
        }
        props.setLegendMenuOn(!curState);
    }

    return (
        <div>
            <LegendMenuOpenButton style={{marginRight:'2em'}}
                toggleLegendMenu={toggleLegendMenu}
                legendMenuOn={props.legendMenuOn}
            />
            <Collapse
                in={props.legendMenuOn}
                {...(props.layerMenuOn ? { timeout: 1000 } : {timeout: 0})}
                style={{ transformOrigin:'top' }}
            >
                <Paper elevation={3} sx={sxStyles.legendContainer} >
                    <LegendMenuContent
                        layerToggles={props.layerToggles}
                        styleInfo={props.styleInfo}
                        olLayerState={props.olLayerState}
                        toggleLegendMenu={toggleLegendMenu}
                    />
                </Paper>
            </Collapse>
        </div>
    );
}
export default LegendMenu;

