import React from 'react';
import { Typography, ListSubheader } from '@mui/material';


const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    },
};

/**
* Displays legend image with a label above it
*
* @prop (obj) VLMStyleInfo - legend info that pertains only to VLM (derived from top-level state obj: styleInfo)
*
* NOTE: dynamic styles not enabled for this layer. This component always grabs first available legend with [0]
*/
function VLMLegend(props){

    if (!props.VLMStyleInfo) return null;
    return (
        <ListSubheader component="div" >
            <Typography sx={sxStyles.label}>Vertical Land Motion</Typography>
            <Typography sx={sxStyles.label}>{props.VLMStyleInfo["vlm_velocity"][0].title}</Typography>
            <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={props.VLMStyleInfo["vlm_velocity"][0].url} />
            <Typography sx={sxStyles.label}>{props.VLMStyleInfo["vlm_acceleration"][0].title}</Typography>
            <img style={{ margin: '0px 0px 0px -18px'}} alt="" src={props.VLMStyleInfo["vlm_acceleration"][0].url} />
        </ListSubheader>
    );
}

export default VLMLegend;