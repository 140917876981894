import React, { useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Link, TableHead } from '@mui/material';

import FeatureInfoAccordion from './feature-info-accordion'
import useHighlightLayer from './hooks/use-highlight-layer'

import { OL_ZINDEXES, ZONE_FORECAST_LAYERS, RIP_CURRENT_COLORS } from '../../config';
import { beachLegend } from '../menu/legend-menu-items/zone-forecasts-legend.js';

const sxStyles = {
    tableCell: {
        fontSize: '0.8em',
    },
    tableHeader: {
        border: 'none',
    },
    legendCell: {
        marginLeft: '8px'
    },
    linkCell: {
        whiteSpace: 'nowrap',
        fontSize: '0.8em',
    },
    subheaderCell: {
        fontSize: '1em',
        fontWeight: 'bold'
    },
    rowHeader: {
        fontSize: '0.8em',
        whiteSpace: 'nowrap',
    },
    bulletinLink: {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
            color: 'secondary.light',
        },
        whiteSpace: 'nowrap'
    }
};

// JSS styles for non-mui components
const classes = {
    zoneForecastsFeatureInfoContainer: {
        marginTop: '10px',
    },
};

const dateFormat = {
    dateStyle: "medium",
    timeStyle: "long",
    timeZone: "UTC"
};

/**
* Component for rendering results from getFeatureInfo requests
*
* @prop (obj) map - OL map object used for drawing feature info highlights
* @prop (obj) data - json response obj from getFeatureInfo request
* @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
**/
export default function ZoneForecastsFeatureInfo(props) {

    const [productsOpen, setProductsOpen] = useState({});

    const [clearHighlight, drawFeatureHighlight] = useHighlightLayer(props.map, OL_ZINDEXES.zone_forecasts_highlight_layer);

    let layerCount = 0;
    for (const layer in props.data) {
        if (Object.keys(props.data[layer]).length !== 0) {
            layerCount = layerCount + 1;
        }
    }

    // Get all unique zone types
    const forecastTypes = new Set();
    for (const layer in props.data) {
        if (props.data[layer].features) {
            if (props.data[layer].features.length > 0 && props.olLayerState[layer].on && ZONE_FORECAST_LAYERS[layer].group !== 'beach') {
                forecastTypes.add(layer);
            }
        }
    }

    if(forecastTypes.has("offshore_zone_forecasts")) {
        forecastTypes.delete("coastal_marine_zone_forecasts");
    }

    const beachTypes = new Set();
    for (const layer in props.data) {
        if (props.data[layer].features) {
            if (props.data[layer].features.length > 0 && props.olLayerState[layer].on && ZONE_FORECAST_LAYERS[layer].group === 'beach') {
                beachTypes.add(layer);
            }
        }
    }

    if(beachTypes.has("great_lakes_beach_forecasts_day1") || beachTypes.has("great_lakes_beach_forecasts_day2")) {
        if(beachTypes.has("beach_forecasts_day1")) {
            beachTypes.delete("beach_forecasts_day1");
        }
        if(beachTypes.has("beach_forecasts_day2")) {
            beachTypes.delete("beach_forecasts_day2");
        }
    }

    // Handle drawing of GeoJSON features returned by getFeatureInfo
    clearHighlight();
    if (Object.keys(props.data).length > 0) {
        for (const layer in props.data) {
            if (props.data && props.data[layer] && props.data[layer].features) {
                for (const feature of props.data[layer].features) {
                    if(productsOpen[layer] && productsOpen[layer] === true) {
                        drawFeatureHighlight(feature);
                    }
                }
            }
        }
    }

    //if not all feature service requests have responded
    if (layerCount < Object.keys(props.data).length) {
        return(<CircularProgress sx={{ml: "10em", mt: "10px"}} />);
    }

    //if no zone features at click location
    if (forecastTypes.size === 0 && beachTypes.size === 0) {
        return(
            <div style={{padding: '40px 30px'}}>
                <Typography sx={{fontSize: '0.8em'}}>No Results Found</Typography>
                <br />
                <Typography sx={{fontSize: '0.8em'}}>No data values, hyperlinks or other information were found for this location.</Typography>
            </div>
        );
    }

    let zoneContent = [...forecastTypes].map((forecastType,index) => {
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft: "10px solid " + ZONE_FORECAST_LAYERS[forecastType].color}}
                featureName={ZONE_FORECAST_LAYERS[forecastType].label}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [forecastType]: !prevState[forecastType]})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                {
                    props.data[forecastType].features.map((feature, index) => {
                        return(
                            <TableContainer key={index}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow sx={sxStyles.tableHeader}>
                                            {feature.properties.zoneurl ?
                                            <TableCell><Link href={feature.properties.zoneurl} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Forecast</Link></TableCell>
                                            : <TableCell><Link href={feature.properties.url} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Forecast</Link></TableCell> }
                                            <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Zone ID:</TableCell>
                                            {feature.properties.zone ?
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.zone}</TableCell>
                                            : <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.id}</TableCell> }
                                        </TableRow>
                                        <TableRow>
                                        {forecastType === 'coastal_marine_zone_forecasts' || forecastType === 'public_weather_zones' || forecastType === 'fire_weather_zones' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO ID:</TableCell>
                                        : forecastType === 'offshore_zone_forecasts' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO or Center ID:</TableCell>
                                        : forecastType === 'high_seas_zone_forecasts' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Center ID:</TableCell>
                                        : null }
                                            {feature.properties.cwa ?
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.cwa}</TableCell>
                                            : <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.wfo}</TableCell> }
                                        </TableRow>
                                        <TableRow>
                                            {forecastType === 'public_weather_zones' || forecastType === 'fire_weather_zones' ?
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Name:</TableCell>
                                            : <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>}
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.name}</TableCell>
                                        </TableRow>
                                        {forecastType === 'public_weather_zones' || forecastType === 'fire_weather_zones' ?
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>State:</TableCell>
                                            <TableCell align="left" sx={sxStyles.tableCell}>{feature.properties.state}</TableCell>
                                        </TableRow>
                                        : null }
                                        <TableRow>
                                            <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                            <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(feature.properties.idp_filedate)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    })
                }
                </div>
            </FeatureInfoAccordion>
        )
    });

    let beachContent = [...beachTypes].map((beachType,index) => {
        return (
            <FeatureInfoAccordion key={index}
                styleOverride={{borderLeft: "10px solid " + getBorderColor(props.data[beachType].features[0])}}
                featureName={"Beach and Surf Zones"}
                setFeatureIsOn={() => {setProductsOpen((prevState) => {return({...prevState, [beachType]: !prevState[beachType]})})}}
            >
                <div style={{padding: '5px 0px 10px 0px'}}>
                    <TableContainer key={index}>
                        <Table size="small">
                            <TableHead>
                                <TableRow sx={sxStyles.tableHeader}>
                                    {props.data[beachType].features[0].properties.srfprod ?
                                    <TableCell><Link href={props.data[beachType].features[0].properties.srfprod} sx={sxStyles.bulletinLink} target="_blank" rel="noopener noreferrer">View Complete Forecast</Link></TableCell>
                                    : <TableCell sx={sxStyles.linkCell}>Forecast Unavailable</TableCell> }
                                    <TableCell align="left" sx={sxStyles.tableCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Zone ID:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data[beachType].features[0].properties.id.length > 6 ? props.data[beachType].features[0].properties.id.slice(-6).toUpperCase() : props.data[beachType].features[0].properties.id.toUpperCase()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>WFO ID:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data[beachType].features[0].properties.siteid.toUpperCase()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Location:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data[beachType].features[0].properties.beachname}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Service Date:</TableCell>
                                    <TableCell align="left" sx={sxStyles.tableCell}>{Intl.DateTimeFormat("en-US", dateFormat).format(props.data[beachType].features[0].properties.idp_filedate)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={sxStyles.linkCell} colSpan={2}><Typography sx={sxStyles.subheaderCell}>Today's Forecast</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    {beachType.includes("great_lakes") ?
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Swim Risk:</TableCell>
                                    : <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Rip Current Risk:</TableCell> }
                                    {props.data[beachType].features[0].properties.rip && beachType.includes("great_lakes") ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["great_lakes_beach_forecasts_day1"].features[0].properties.rip.replace(/\.$/, '')}</TableCell>
                                    : props.data[beachType].features[0].properties.rip ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["beach_forecasts_day1"].features[0].properties.rip.replace(/\.$/, '')}</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Surf Height:</TableCell>
                                    {props.data[beachType].features[0].properties.surf && beachType.includes("great_lakes") ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["great_lakes_beach_forecasts_day1"].features[0].properties.surf.replace(/\.$/, '')}  ({convertSurfToMetric(props.data["beach_forecasts_day1"].features[0].properties.surf.replace(/\.$/, ''))})</TableCell>
                                    : props.data[beachType].features[0].properties.surf ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["beach_forecasts_day1"].features[0].properties.surf.replace(/\.$/, '')} ({convertSurfToMetric(props.data["beach_forecasts_day1"].features[0].properties.surf.replace(/\.$/, ''))})</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={sxStyles.linkCell} colSpan={2}><Typography sx={sxStyles.subheaderCell}>Tomorrow's Forecast</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    {beachType.includes("great_lakes") ?
                                    <TableCell component="th" scope="row" sx={sxStyles.rowHeader}>Swim Risk:</TableCell>
                                    : <TableCell component="th" scope="row" sx={sxStyles.rowHeader}>Rip Current Risk:</TableCell> }
                                    {props.data[beachType].features[0].properties.rip && beachType.includes("great_lakes") ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["great_lakes_beach_forecasts_day2"].features[0].properties.rip.replace(/\.$/, '')}</TableCell>
                                    : props.data[beachType].features[0].properties.rip ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["beach_forecasts_day2"].features[0].properties.rip.replace(/\.$/, '')}</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={sxStyles.tableCell}>Surf Height:</TableCell>
                                    {props.data[beachType].features[0].properties.surf && beachType.includes("great_lakes") ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["great_lakes_beach_forecasts_day2"].features[0].properties.surf.replace(/\.$/, '')} ({convertSurfToMetric(props.data["beach_forecasts_day2"].features[0].properties.surf.replace(/\.$/, ''))})</TableCell>
                                    : props.data[beachType].features[0].properties.surf ?
                                    <TableCell align="left" sx={sxStyles.tableCell}>{props.data["beach_forecasts_day2"].features[0].properties.surf.replace(/\.$/, '')} ({convertSurfToMetric(props.data["beach_forecasts_day2"].features[0].properties.surf.replace(/\.$/, ''))})</TableCell>
                                    : <TableCell align="left" sx={sxStyles.tableCell}>Not Available</TableCell> }
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" colSpan={2} align="center" sx={sxStyles.legendCell}>
                                        {beachLegend(props)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </FeatureInfoAccordion>
        )
    });

    return(
        <div style={classes.zoneForecastsFeatureInfoContainer}>
            {beachContent}
            {zoneContent}
        </div>
    );
}

function getBorderColor(feature) {
    var color;
    let rip = feature.properties.rip;
    if(RIP_CURRENT_COLORS.hasOwnProperty(rip)) {
        color = RIP_CURRENT_COLORS[rip];
    } else {
        color = RIP_CURRENT_COLORS["Not Provided"];
    }
    return color;
}

function convertSurfToMetric(string) {
    var newString = string.replace(/\d+/g, function(m) { return (0.3048*parseFloat(m)).toFixed(2)+''; }).replaceAll('feet', 'm').replaceAll('foot', 'm');
    return newString;
}