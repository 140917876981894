import React from 'react';
import { Select, InputLabel, MenuItem, Typography, CircularProgress, FormLabel} from '@mui/material';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import MRMSQPELegend from '../legend-menu-items/mrms-qpe-legend.js';


const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
};

const MRMS_QPE_LAYER_INFO = {
//    "mrms_qpe:rft_1hr": "1 Hour Accumulation",
//    "mrms_qpe:rft_3hr": "3 Hour Accumulation",
//    "mrms_qpe:rft_6hr": "6 Hour Accumulation",
//    "mrms_qpe:rft_12hr": "12 Hour Accumulation",
//    "mrms_qpe:rft_24hr": "24 Hour Accumulation",
//    "mrms_qpe:rft_48hr": "48 Hour Accumulation",
//    "mrms_qpe:rft_72hr": "72 Hour Accumulation",
    "mrms_qpe:rft_1hr:unknown@gpml": "1-hr",
    "mrms_qpe:rft_3hr:unknown@gpml": "3-hr",
    "mrms_qpe:rft_6hr:unknown@gpml": "6-hr",
    "mrms_qpe:rft_12hr:unknown@gpml": "12-hr",
    "mrms_qpe:rft_24hr:unknown@gpml": "24-hr",
    "mrms_qpe:rft_48hr:unknown@gpml": "48-hr",
    "mrms_qpe:rft_72hr:unknown@gpml": "72-hr",
}

/**
* MRMSQPESelect: Selects current variable to be displayed for MRMS QPE
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function MRMSQPESelect(props) {

    const MRMSQPELayerTypeOptions = Object.entries(MRMS_QPE_LAYER_INFO).map(([durationValue, durationLabel],i) => {
        return (
            <MenuItem key={i} value={durationValue}>{durationLabel}</MenuItem>
        );
    });
    // DLETE THIS  "renderingRule": "%7B%22rasterFunction%22%3A%22rft_48hr%22%7D"
    // Parse currently selected duration
    // REQUIRES: mrms_qpe expects customParams obj containing only "renderingRule: <value containing rft_xhr>"
//    console.log(props.olLayerState.mrms_qpe)
//    let curDurationParam = props.olLayerState.mrms_qpe.customParams.renderingRule;
//    let startIndex = curDurationParam.indexOf("rft_");
//    let endIndex = curDurationParam.indexOf("hr") + 2;
//    let curDurationKey = curDurationParam.slice(startIndex, endIndex);
//    console.log(">>>>>>>>>> We got the duration key ", curDurationKey);

    return (
        <div>
            <InputLabel id="MRMSQPE_select_label" sx={{fontSize: '90%'}}>Select QPE Duration</InputLabel>
            <Select
                autoWidth
                size="small"
                labelId="MRMSQPE_select_label"
                value={props.olLayerState.mrms_qpe.layersParam}
                onChange={(e) => props.updateOlLayerState({
                    mrms_qpe: {
                        ...props.olLayerState.mrms_qpe,
                        layersParam: e.target.value
                    }
                })}
            >
                {MRMSQPELayerTypeOptions}
            </Select>
        </div>
    );
}

/**
* MRMSQPELayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) qpeActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setQpeActive - callback for setting qpeActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (obj) styleInfo - Contains legend info just for mrms qpe
*   @prop (react component) capUrlsContent - component containing content to display under capUrls tab
*/
function MRMSQPELayerMenuItem(props){

    return (
        <LayerMenuItem fontSize="small"
            layerName={"mrms_qpe"}
            label={"Precipitation Amounts"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.qpeActive}
            setLayerIsActive={props.setQpeActive}
            updateBasemap={props.updateBasemap}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"mrms_qpe"}
                    layersList={props.layersList}
                    infoContent={<Typography variant="caption">Latest NWS Multi-Radar Multi-Sensor (MRMS) quantitative precipitation estimate (QPE) mosaics for 1-, 3-, 6-, 12-, 24-, 48-, and 72-hr time periods at a 1 km (0.6 miles) horizontal resolution for CONUS, southern part of Canada,  Alaska, Hawaii, and Puerto Rico. The QPEs are based only on weather radar data. The QPEs are updated every hour for the period ending at the top of the hour. Source: NWS and OAR/NSSL</Typography>}
                    legendContent={
                        props.styleInfo ?
                            <MRMSQPELegend
                                MRMSQPEStyleInfo={props.styleInfo}
                                olLayerState={props.olLayerState}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={props.capUrlsContent}
                >
                    <FormLabel><Typography variant="overline">EXTERNAL SERVICE</Typography></FormLabel>
                    <Typography sx={{mb:'10px',mt:'10px'}}>MRMS Quantitative Precipitation Estimates (inches)</Typography>
                    <MRMSQPESelect
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                    />
                    <Typography variant="caption">(updated hourly)</Typography>
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

// old info content:
//<div>
//    <Typography variant="caption">This external imageservice provides maps depicting the NWS Multi-Radar Multi-Sensor (MRMS) quantitative precipitation estimate mosaics in inches for 1-, 3-, 6-, 12-, 24-, 48-, and 72-hr time periods at a 1 km (0.6 miles) horizontal resolution for CONUS, Alaska, Hawaii and Puerto Rico. The precipitation estimates are based only on radar data.</Typography>
//    <br /><br />
//    <Typography variant="caption">The 1-hr, 3-hr, 6-hr, 12-hr, 24-hr, 48-hr, and 72-hr QPEs are updated every hour for the period ending at the top of the hour.</Typography>
//    <br /><br />
//    <Typography variant="caption">For more information about the MRMS system, please see: <a style={classes.links}href="http://www.nssl.noaa.gov/projects/mrms" target="_blank" rel="noopener noreferrer">www.nssl.noaa.gov/projects/mrms</a> </Typography>
//    <br /><br />
//    <Typography variant="caption">Questions/Concerns about this external service, please contact the DISS GIS team at nws.mapservices@noaa.gov.</Typography>
//</div>

export default MRMSQPELayerMenuItem;