import React, { useState } from "react";
import { Typography, CircularProgress } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

import LayerMenuItem from './layer-menu-item.js';
import LayerOptionsContainer from './layer-options-container.js';
import S100Legend from '../legend-menu-items/s100-legend.js';

const classes = {
    menuItemBodyContainer: {
        padding: '0.5em',
    },
};

const sxStyles = {
    links: {
        '& a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:visited': {
                color: 'primary.main'
            }
        }
    },
};

/**
* BoundaryOptions: Check boxes for toggling layers from s100 coverages
*
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state and list of "sources"
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/

function BoundaryOptions(props) {
    const [s111Checked, setS111Checked] = useState(props.olLayerState['s100_approach_coverage'].on && (props.olLayerState['s100_approach_coverage'].stylesParam[0]==="s111_approach_coverage" || props.olLayerState['s100_approach_coverage'].stylesParam[0]==="s100_approach_coverage"));
    const [s102Checked, setS102Checked] = useState(props.olLayerState['s100_approach_coverage'].on && (props.olLayerState['s100_approach_coverage'].stylesParam[0]==="s102_approach_coverage" || props.olLayerState['s100_approach_coverage'].stylesParam[0]==="s100_approach_coverage"));

    const handleApproachCoverage = (s100Product) => {

        if (s100Product==='s111') {
            if (s111Checked===true && s102Checked===false) { // Both will be off so turn off layer (doesn't matter what happens to stylesParam)
                props.updateOlLayerState({'on': false, 'stylesParam': ['s111_approach_coverage']}, 's100_approach_coverage')
                setS111Checked(false)
            }
            if (s111Checked===false && s102Checked===false) { // Turn on s111 approach only
                props.updateOlLayerState({'on': true, 'stylesParam': ['s111_approach_coverage']}, 's100_approach_coverage')
                setS111Checked(true)
            }
            if (s111Checked===false && s102Checked===true) { // Both will be on
                props.updateOlLayerState({'on': true, 'stylesParam': ['s100_approach_coverage']}, 's100_approach_coverage')
                setS111Checked(true)
            }
            if (s111Checked===true && s102Checked===true) { // Turning off s111 approach and leaving s102 approach on
                props.updateOlLayerState({'on': true, 'stylesParam': ['s102_approach_coverage']}, 's100_approach_coverage')
                setS111Checked(false)
            }
        }

        if (s100Product==='s102') {
            if (s102Checked===true && s111Checked===false) { // Both will be off so turn off layer (doesn't matter what happens to stylesParam)
                props.updateOlLayerState({'on': false, 'stylesParam': ['s102_approach_coverage']}, 's100_approach_coverage')
                setS102Checked(false)
            }
            if (s102Checked===false && s111Checked===false) { // Turning on s102 approach only
                props.updateOlLayerState({'on': true, 'stylesParam': ['s102_approach_coverage']}, 's100_approach_coverage')
                setS102Checked(true)
            }
            if (s102Checked===false && s111Checked===true) { // Both will be on
                props.updateOlLayerState({'on': true, 'stylesParam': ['s100_approach_coverage']}, 's100_approach_coverage')
                setS102Checked(true)
            }
            if (s102Checked===true && s111Checked===true) { // Turning off s102 approach and leaving s111 approach on
                props.updateOlLayerState({'on': true, 'stylesParam': ['s111_approach_coverage']}, 's100_approach_coverage')
                setS102Checked(false)
            }
        }
    };

    return (

        <FormGroup>

            <FormLabel><Typography variant="overline">NOAA S-111 (Surface Currents)</Typography></FormLabel>
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["s100_general_coverage"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["s100_general_coverage"].on}, "s100_general_coverage")}} />}
                label={<Typography variant="caption">General Navigation (4-8.5 km)  </Typography>}
            />
            <FormControlLabel
                control={<Checkbox size="small" checked={s111Checked} onChange={() => {handleApproachCoverage("s111")}} />}
                label={<Typography variant="caption">Navigational Approach (300-700 m)</Typography>}
            />

            <FormLabel><Typography variant="overline">NOAA S-102 (Bathymetric Surface)</Typography></FormLabel>

            <FormControlLabel
                control={<Checkbox size="small" checked={s102Checked} onChange={() => {handleApproachCoverage("s102")}} />}
                label={<Typography variant="caption">Navigational Approach (16 m)</Typography>}
            />
            <FormControlLabel
                control={<Checkbox size="small" checked={props.olLayerState["s100_harbor_coverage"].on} onChange={() => {props.updateOlLayerState({'on': !props.olLayerState["s100_harbor_coverage"].on}, "s100_harbor_coverage")}} />}
                label={<Typography variant="caption">Harbor Navigation (4 m)</Typography>}
            />
            </FormGroup>

    );
}

/**
* S100LayerMenuItem: Customized instance of generic LayerMenuItem
*
*   @prop (obj) layerToggles - maps layerNames to their toggle state (true/false for on/off)
*   @prop (func) updateLayerToggles - callback for updating layerToggles
*   @prop (bool) productActive - true if layer is active (should be displayed in active layers menu)
*   @prop (func) setProductActive - callback for setting encActive
*   @prop (bool) layerInitialized - false if layer relies on Capabilities and has not yet been initialized
*   @prop (bool) onlyDisplayActive - true if active layers filter is On (only displaying active layers in menu)
*   @prop (obj) olLayerState - maps ol layer names to obj containing "on" state, style and layer params, as well as current source
*   @prop (func) updateOlLayerState - callback for updating olLayerState
*/
function S100LayerMenuItem(props){

    let currentAbstract = <>

        <Typography variant="caption">S-100 is an international hydrographic geospatial data standard that can support
        a wide variety of hydrographic-related digital data sources. NOAA is an active participant with other countries
        in the development of the S-100 standards and associated individual product specifications. NOAA is working to
        align their maritime hydrographic, meteorological, and oceanographic products to the S-100 suite of specifications
        under development. These efforts will enable mariners to have NOS and NWS products integrated within their marine
        navigation display systems to help them make critical navigational decisions.</Typography>
        <br /><br />
        <Typography variant="caption">The S-100 Product Coverage service provides maps of the present geographic coverage
        for NOAA’s S-111 Surface Currents and S-102 Bathymetric Surface information being developed to support Precision
        Marine Navigation. NOAA's S-100 products are arranged in a tiling and naming scheme
        corresponding to the NOAA Reschemed Electronic Navigational Charts (ENC). Users can select the maps to obtain
        basic metadata for the S-111 and/or S-102 data for the geographic area of interest and download datasets for
        the selected area, made available by the <a href="https://www.noaa.gov/information-technology/open-data-dissemination" target="_blank" rel="noopener noreferrer">NOAA Open Data Dissemination Program</a>.
        For further information about products and data access visit <a href="https://registry.opendata.aws/noaa-s111/" target="_blank" rel="noopener noreferrer">NOAA S-111 Open Data </a>
        and <a href="https://registry.opendata.aws/noaa-s102/" target="_blank" rel="noopener noreferrer">NOAA S-102 Open Data</a>.
        The coverage maps will be updated as more NOAA S-100 products become available.
        </Typography>

    </>;

    const links = <>
        <Typography variant='caption'>Get NOAA S-100 Data</Typography>
        <br />
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer">S-102 Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer">S-111 Datasets</a></Typography>
        <br />
        <br />
        <Typography variant='caption'>Get NOAA S-102 Data by Region</Typography>
        <br />
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/approaches_to_new_york/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 Approaches to New York Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/new_york_harbor/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 New York Harbor Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/hudson_river/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 Hudson River Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/boston/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 Boston Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/savannah/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 Savannah Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/charleston/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 Charleston Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s102-pds.s3.amazonaws.com/index.html#ed2.1.0/national_bathymetric_source/los_angeles_long_beach/dcf2/tiles/" target="_blank" rel="noopener noreferrer">S-102 Ports of Los Angeles/Long Beach Datasets</a></Typography>
        <br />
        <br />
        <Typography variant='caption'>Get NOAA S-111 Data by Region</Typography>
        <br />
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/cbofs/" target="_blank" rel="noopener noreferrer">S-111 Chesapeake Bay Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/dbofs/" target="_blank" rel="noopener noreferrer">S-111 Delaware Bay Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/gomofs/" target="_blank" rel="noopener noreferrer">S-111 Gulf of Maine Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/nyofs/" target="_blank" rel="noopener noreferrer">S-111 Ports of New York/New Jersey Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/tbofs/" target="_blank" rel="noopener noreferrer">S-111 Tampa Bay Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/ngofs2/" target="_blank" rel="noopener noreferrer">S-111 Northern Gulf of Mexico Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/sfbofs/" target="_blank" rel="noopener noreferrer">S-111 San Francisco Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/leofs/" target="_blank" rel="noopener noreferrer">S-111 Lake Erie Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/loofs/" target="_blank" rel="noopener noreferrer">S-111 Lake Ontario Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/lsofs/" target="_blank" rel="noopener noreferrer">S-111 Lake Superior Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/lmhofs/" target="_blank" rel="noopener noreferrer">S-111 Lake Michigan & Huron Datasets</a></Typography>
        <br />
        <Typography variant='caption' sx={sxStyles.links}><a href="https://noaa-s111-pds.s3.amazonaws.com/index.html#ed1.0.1/model_forecast_guidance/wcofs/" target="_blank" rel="noopener noreferrer">S-111 West Coast Datasets</a></Typography>
    </>;

    return (
        <LayerMenuItem
            layerName={"s100"}
            label={"S-100 Product Coverages"}
            layerToggles={props.layerToggles}
            updateLayerToggles={props.updateLayerToggles}
            layerInitialized={props.layerInitialized}
            onlyDisplayActive={props.onlyDisplayActive}
            layerIsActive={props.productActive}
            setLayerIsActive={props.setProductActive}
            updateBasemap={props.updateBasemap}
        >
            <div style={classes.menuItemBodyContainer}>
                <LayerOptionsContainer
                    opacity={props.opacity}
                    updateLayerOpacities={props.updateLayerOpacities}
                    layerName={"s100"}
                    layersList={props.layersList}
                    infoContent={currentAbstract}
                    legendContent={
                        props.styleInfo ?
                            <S100Legend
                                S100StyleInfo={props.styleInfo}
                            />
                        : <CircularProgress />
                    }
                    capUrlsContent={links}
                >
                    <BoundaryOptions
                        olLayerState={props.olLayerState}
                        updateOlLayerState={props.updateOlLayerState}
                        activeStylesList={props.activeStylesList}
                        s100StyleInfo={props.styleInfo}
                    />
                </LayerOptionsContainer>
            </div>
        </LayerMenuItem>
    );
}

export default S100LayerMenuItem;