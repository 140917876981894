import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

const classes ={
    VLMFeatureInfoContainer: {
        padding: '10px'
    }
};

const sxStyles = {
    label: {
        color: '#252525',
        fontSize: '98%',
    }
};

/**
* Component for rendering results from getFeatureInfo wms requests
*
* @prop (obj) data - json response obj from getFeatureInfo request
* @prop (obj) styleInfo - legend info that pertains only to VLM (derived from top-level state obj: styleInfo)
**/
export default function VLMFeatureInfo(props) {
    if (Object.keys(props.data["vlm_velocity"]).length === 0) {
        return(<CircularProgress sx={{margin: '70px 20px'}} />);
    }

    if (props.data["vlm_velocity"].features[0] && (props.data["vlm_velocity"].features[0].properties['Band1'] > -1000)) {
        return(
            <div className={classes.VLMFeatureInfoContainer}>
                <Typography align="center">Vertical Land Motion (2017-2023)</Typography>
                <div style={{
                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                    marginLeft: 10, marginRight: 10,
                    }}
                >
                </div>
                <br />
                {props.data["vlm_velocity"] ?
                <div className={classes.VLMFeatureInfoContainer}>
                    <Typography sx={sxStyles.label} align="center">Average Annual Velocity</Typography>
                    <Typography sx={{fontSize: '1.0em'}} align="center">
                        {props.data["vlm_velocity"].features[0].properties['Band1'].toFixed(2)} mm/yr
                    </Typography>
                </div>
                : null}
                <br />
                {props.data["vlm_acceleration"] ?
                <div className={classes.VLMFeatureInfoContainer}>
                    <Typography sx={sxStyles.label} align="center">Average Annual Acceleration</Typography>
                    <Typography sx={{fontSize: '1.0em'}} align="center">
                        {props.data["vlm_acceleration"].features[0].properties['Band1'].toFixed(2)} mm/yr<sup>2</sup>
                    </Typography>
                </div>
                : null}
                <br />
            </div>
        );
    } else {
        return(
            <div style={{padding: '50px 20px'}}>
                <Typography sx={{fontSize: '0.8em'}} >No feature info found at the specified location. Please try again in another location.</Typography>
            </div>
        );
    }
}